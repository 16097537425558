<template>
  <div>
    <template v-if="controls !== false && group === true && controls.length > 1">
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            :disabled="invalid"
            small
            v-on="on"
          >
            ...
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item
              v-for="(control,index) in controls"
              :key="index"
            >
              <v-list-item-title>
                <v-btn
                  dark
                  :color="control.c"
                  small
                  @click="control.a(item)"
                >
                  {{ control.t }}
                  <template v-if="control.i">
                    <v-icon
                      :color="control.c"
                      right
                    >
                      {{ control.i }}
                    </v-icon>
                  </template>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <template v-if="controls !== false && (controls.length === 1 || group === false) && controls.length > 0">
      <v-list>
        <template
          v-for="(control,index) in controls"
        >
          <v-list-item :key="index">
            <v-list-item-content>
              <v-btn
                :key="index"
                dark
                :color="control.c"
                height="25"
                @click="control.xn ? XacNhan(item,control.a):control.a(item)"
              >
                {{ control.t }}
                <template v-if="control.i">
                  <v-icon
                    right
                  >
                    {{ control.i }}
                  </v-icon>
                </template>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </div>
</template>

<script>
import { ThongBao } from '@/helpers'

export default {
  props: {
    controls: {
      type: [Array,Boolean],
      default: false,
      required: false,
    },
    group:{
      type: Boolean,
      default: true,
    },
    item: Object
  },
  computed: {
    invalid(){
      if(this.controls === false || this.controls.length === 0) return true
      return false
    }
  },
  methods:{
    async XacNhan(i,c){
      const confirm = await ThongBao.XacNhan("Bạn có xác nhận ? Hành động này không thể khôi phục !!!");
      if(confirm === false) return;
      return c(i)
    }
  }
}
</script>

<style>

</style>